/* MOBILE FIRST - default styles is for mobile and media is only for large screens */

:root {
  font-size: 24px;
  --font-family-primary: "Roboto", sans-serif;
  --font-family-secondary: "Fira Code", monospace;
  --color-bg: #f4f9fc;
  --color-heading: #0f1b61;
  --color-accent: #73bbc5;
  --color-text: #0f1b61;
  --color-primary: #7f00e0;
  --radius: 3px;
  --width: 1.5px;
  --transition: all 0.5s ease;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --overflow: auto;
}
body {
  font-family: var(--font-family-primary);
  color: var(--color-text);
  background-color: var(--color-bg);
  overflow: var(--overflow);
  margin: 0;
}
p {
  font-size: medium;
}
h4 {
  margin: 0.1rem 0 0 0.3rem;
}
body::-webkit-scrollbar {
  display: none;
}
*::-webkit-scrollbar {
  height: 0.3rem;
}
*::-webkit-scrollbar-track {
  background: var(--color-bg);
}
*::-webkit-scrollbar-thumb {
  background: var(--color-primary);
}
.font-code {
  font-family: var(--font-family-secondary);
  font-size: smaller;
  color: var(--color-primary);
}
.color-primary {
  color: var(--color-primary) !important;
}

/* App.js */
.body {
  max-width: 100vw;
}
.container {
  overflow: hidden;
  padding: 0 1.5rem;
}

/* Navigation & SideNavbar.js */
.nav-buttons {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: var(--color-bg);
  box-shadow: var(--light-shadow);
  padding: 0.5rem 1rem;
}
.nav-link-theme {
  color: var(--color-primary) !important;
  z-index: 2;
  grid-area: theme;
}
.nav-link-side {
  color: var(--color-primary) !important;
  z-index: 3;
  grid-area: side;
  position: absolute;
  bottom: 0.3rem;
  right: 1.5rem;
}
.nav-side {
  padding-left: 30vw;
  background-color: var(--color-accent);
  width: 100%;
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  visibility: hidden;
  transition: var(--transition);
  transform: translateX(50%);
  line-height: 3rem;
}
.nav-active {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
  transition: var(--transition);
}
.underline-nav {
  padding: 1rem 0;
  position: relative;
  font-weight: bolder;
  margin: auto;
}
.underline-nav:hover::before {
  position: absolute;
  content: "";
  background: var(--color-primary);
  width: 45vw;
  height: 0.6rem;
  top: 2.8rem;
  left: -40vw;
}

/* BURGER */
.bar1,
.bar2,
.bar3 {
  width: 1.5rem;
  border-radius: var(--radius);
  height: 2px;
  background-color: var(--color-primary);
  margin: 0.45rem 0;
}
.burger-open .bar1 {
  transform: rotate(45deg) translateY(0.82rem);
  transition: var(--transition);
}
.burger-open .bar3 {
  transform: rotate(-45deg) translateY(-0.82rem);
  transition: var(--transition);
}
.burger-open .bar2 {
  visibility: hidden;
}
.burger-close .bar1,
.burger-close .bar3 {
  transform: rotate(0deg);
  transition: var(--transition);
}
/* THEME CHANGER & Theme.js */
.theme {
  position: sticky;
  top: 0;
  background-color: var(--color-bg);
}

.theme-popup {
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  height: 0;
  top: 1rem;
  transition: var(--transition);
  transform: translateY(-50%);
}
.theme-active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: var(--transition);
  height: fit-content;
  height: 100%;
}
.theme-title {
  text-align: center;
  padding-bottom: 1rem;
}
.theme-container {
  display: grid;
  grid-auto-flow: column;
  overflow-y: hidden;
  overflow-x: scroll;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
.theme-cards {
  border: var(--width) solid var(--color-primary);
  border-radius: var(--radius);
  padding: 1rem 0rem;
  width: 150px;
  margin-right: 0.5rem;
  text-align: center;
}
.color-dots {
  text-align: center;
}
.color-dot {
  margin: 0.1rem;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  display: inline-block;
  box-shadow: var(--dark-shadow);
}

/* UNDERLINE */
.underline {
  width: fit-content;
  margin: 1rem 0;
  position: relative;
  z-index: 1;
}
.underline::before {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  border-bottom: 0.7rem solid var(--color-accent);
  bottom: 0.2rem;
  animation: underline 1s 2s forwards;
}

/* BUTTONS including font-awesome icons and SVG */
.btn {
  display: inline-block;
  width: fit-content;
  padding: 1px;
  margin: 0.5rem 0;
  font-family: var(--font-family-secondary);
  font-weight: bold;
  border-radius: var(--radius);
  position: relative;
  transition: ease-in-out 1s;
  cursor: pointer;
  z-index: 50;
}

.btn-background {
  display: inline-block;
  padding: 0.8rem 1rem;
  position: relative;
  width: fit-content;
  z-index: 3;
  color: var(--color-bg) !important;
  transition: color ease-in-out 0.3s;
}

.btn-background:hover {
  color: var(--color-text) !important;
  transition: color ease-in-out 0.3s;
}

.btn-background::after {
  background-color: var(--color-primary);
  z-index: -1;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: var(--radius);
  transition: transform ease 0.5s, background-color ease-in-out 0.4s;
}

.btn-background:hover::after {
  background-color: rgba(127, 0, 224, 0.1) !important;
  transform: translate(10px, 10px);
  transition: transform ease 0.5s, background-color ease-in-out 0.4s;
}

.btn.btn-border::after,
.btn.btn-border::before {
  position: absolute;
  content: "";
  width: 0%;
  height: 0%;
  visibility: hidden;
}

.btn.btn-border::after {
  bottom: 0px;
  right: 0px;
  border-left: 1px solid var(--color-primary);
  border-bottom: 1px solid var(--color-primary);
  border-radius: var(--radius);
  transition: width 0.1s ease 0.1s, height 0.1s ease, visibility 0s 0.2s;
}

.btn.btn-border::before {
  top: 0;
  left: 0;
  border-top: 1px solid var(--color-primary);
  border-right: 1px solid var(--color-primary);
  border-radius: var(--radius);
  transition: width 0.1s ease 0.3s, height 0.1s ease 0.2s, visibility 0s 0.4s;
}

.btn.btn-border:hover::after,
.btn.btn-border:hover::before {
  width: calc(100% - 1px);
  height: calc(100% - 1px);
  visibility: visible;
  transition: width 0.1s ease 0.2s, height 0.1s ease 0.3s, visibility 0s 0.2s;
}

.btn.btn-border:hover::after {
  transition: width 0.1s ease 0.2s, height 0.1s ease 0.3s, visibility 0s 0.2s;
}

.btn.btn-border:hover::before {
  transition: width 0.1s ease, height 0.1s ease 0.1s;
}

.btn-text:hover {
  transition: transform ease-in-out 1s;
  transform: translate(0);
  background: var(--color-secondary);
}

.btn-text:hover::before {
  content: "";
  position: absolute;
  border-radius: var(--radius);
  border: 1px solid var(--color-primary);
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  transform: translate(10px, 10px);
  transition: transform ease-in-out 1s;
}

nav .btn-text {
  padding: 0 2rem;
}
.btn-text:hover {
  color: var(--color-bg) !important;
  background-color: var(--color-primary);
  animation: button-attention 0.8s both;
}
.btn-icons-bottom {
  text-align: center;
  margin-top: 2rem;
  position: relative;
  z-index: 1;
}
.btn-icons-bottom .fab {
  background-color: var(--color-bg);
  padding: 0 0.5rem;
}
.btn-icons-bottom::before {
  content: "";
  position: absolute;
  z-index: -1;
  height: 1px;
  width: 100%;
  background: var(--color-text);
  left: 0;
  top: 10px;
}
.fab:hover,
.fas:hover {
  color: var(--color-primary);
}
.btn-svg {
  width: 2rem;
  padding: 0.5rem 0;
}
.btn-svgs .st2,
.st1 {
  stroke: var(--color-primary) !important;
}

/* IMAGES */
.pic {
  width: 50vw;
  margin: 1rem auto;
  position: relative;
  z-index: 1;
}
.pic img {
  border-radius: var(--radius);
}
.pic::before {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  border: var(--width) solid var(--color-primary);
  border-radius: var(--radius);
  transform: translate(10px, 10px);
}
.pic::after {
  content: "";
  position: absolute;
  background: var(--color-primary);
  opacity: 0.3;
  width: 100%;
  height: 100%;
  left: 0;
  border-radius: var(--radius);
}

/* HeroSection.js */
header {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  position: relative;
}
.header-title {
  font-weight: bold;
  color: var(--color-heading);
  padding-top: 0.5rem;
}
.header-descr {
  font-weight: bold;
  color: var(--color-text);
  margin: 0;
  opacity: 0.8;
}
.header-about {
  opacity: 0.8;
  margin: 1.5rem 0 1rem 0;
  font-size: 1rem;
}
header h1 {
  font-size: 2.6rem;
}
header p:first-child {
  font-size: 1rem;
}

/* About.js */
.about-card {
  padding-top: 1rem;
}
.about-card:nth-child(2n + 1) {
  text-align: right;
}

/* Skills.js */

.skills-descp {
  margin-bottom: 1rem;
}

/* CARDS */
.card,
.card-projects {
  background-color: var(--color-bg);
  position: relative;
  padding: 0.5rem 1rem;
  border-radius: var(--radius);
  margin-bottom: 2rem;
  margin-right: 1rem;
  z-index: 1;
}
.card:nth-child(2n),
.card-projects:nth-child(2n) {
  border: var(--width) var(--color-accent) solid;
}
.card:nth-child(2n)::before,
.card-projects:nth-child(2n)::before {
  background: var(--color-accent);
  opacity: 0.1;
}
.card:nth-child(2n + 1),
.card-projects:nth-child(2n + 1) {
  border: var(--width) var(--color-primary) solid;
}
.card:nth-child(2n + 1)::before,
.card-projects:nth-child(2n + 1)::before {
  background: var(--color-primary);
  opacity: 0.05;
}
.card::before,
.card-projects::before {
  position: absolute;
  content: "";
  border-radius: var(--radius);
  width: 100%;
  height: 100%;
  left: 1rem;
  top: 1rem;
  z-index: -1;
}
.card p {
  padding-left: 0.3rem;
}
.card-title {
  display: grid;
  grid-template-columns: 2fr auto;
  align-items: baseline;
}
.card-title h4 {
  margin: 0.5rem 0 0.5rem 0.3rem;
}
.card-descr {
  padding-bottom: 1rem;
}
.card-progress::after {
  content: "In progress";
  color: var(--color-primary);
  font-family: var(--font-family-secondary);
  font-size: small;
}
.card-links a:nth-child(2) {
  padding-right: 0.5rem;
}
.card-experience-title {
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
}
.card-experience-body p {
  padding-left: 0.3rem;
  padding-top: 0.5rem;
  font-size: medium;
}

/* Contact.js */
.contacts {
  text-align: right;
}

/* Footer.js */
footer p {
  padding: 0 1rem;
  margin-top: 0.5rem;
  text-align: center;
}

/* Visible only in large display */
.aside-left,
.aside-right,
.card-img {
  display: none;
}

/* TABLET */
@media only screen and (min-width: 426px) {
  .container {
    padding: 0 5rem;
  }
  .pic {
    width: 30vw;
  }
  header::before {
    position: absolute;
    content: "";
    background: var(--color-primary);
    opacity: 0.05;
    width: 30vw;
    height: 60vh;
    bottom: 15vh;
    right: -10vh;
    animation: header-primary 10s ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  .header-about::before {
    width: 30vw;
    height: 60vh;
    position: absolute;
    content: "";
    background: var(--color-accent);
    opacity: 0.1;
    bottom: 25vh;
    right: -5vh;
    animation: header-accent 10s ease-in-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    transition: var(--transition);
  }
}

/* LARGE SCREENS - Laptop */
@media only screen and (min-width: 769px) {
  .nav-buttons,
  .btn-icons-bottom {
    display: none !important;
  }
  body {
    line-height: 1.5rem;
  }
  .body {
    padding: 0;
    display: grid;
    grid-template-columns: 4rem auto 4rem;
  }
  .theme-popup {
    position: absolute;
    top: -3rem;
    height: auto;
    width: 87vw;
    background-color: var(--color-bg);
    left: 5vw;
    padding-top: 2rem;
  }
  .theme-title {
    display: none;
  }
  .theme-container {
    border-bottom: 1px solid var(--color-text);
    margin: 0;
  }
  .aside-left {
    padding: 0 1rem;
    position: sticky;
    top: 0;
    height: 100vh;
    display: grid;
    justify-content: center;
    align-items: center;
    color: var(--color-text);
    z-index: 2;
  }
  .aside-svgs,
  .aside-bottom {
    background: var(--color-bg);
    z-index: 1;
    text-align: center;
    padding: 0.5rem 0 0 0;
  }
  .btn-svgs .st2,
  .aside-svg .st1,
  .aside-svg .st2,
  .aside-svg .st0 {
    stroke: var(--color-text) !important;
  }

  .aside-svg > img {
    margin-bottom: 0.5rem;
  }

  .btn-svgs:hover .st2,
  .aside-svg:hover .st2,
  .aside-svg:hover .st1,
  .aside-svg:hover .st0 {
    stroke: var(--color-primary) !important;
  }
  .aside-svg ~ p {
    display: none;
    visibility: hidden;
  }
  .aside-svg:hover ~ p {
    color: var(--color-primary);
    display: inline-flex;
    position: fixed;
    visibility: visible;
    padding-top: 0.25rem;
    padding-left: 0.5rem;
    animation: fade_in_aside 0.5s;
  }
  .aside-right {
    padding: 0 1rem;
    position: sticky;
    top: 0;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: var(--color-text);
  }
  .aside-right p {
    writing-mode: vertical-rl;
    background: var(--color-bg);
    padding: 0.5rem 0;
    color: var(--color-text);
  }
  .aside-right:hover p {
    color: var(--color-primary);
  }
  .aside-left::before,
  .aside-right::before {
    content: "";
    position: absolute;
    background: var(--color-text);
    width: 1px;
    height: 100vh;
    top: 0;
    left: 2rem;
    z-index: -1;
  }
  .fab {
    padding: 0.5rem 0;
  }
  .underline {
    margin: 1rem 0 1.5rem 0;
  }
  header .underline::before {
    bottom: -0.2rem;
  }
  .container {
    padding: 0 4rem;
  }
  #about,
  #skills,
  #projects,
  #experience {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: center;
  }
  .skills-container {
    margin: 0 2rem 2rem 1rem;
  }
  .about-grid-2 {
    display: grid !important;
    grid-template-columns: auto 1fr;
    column-gap: 5vw;
  }
  .about-grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 4vw;
    margin: 1rem;
  }
  .about-card {
    text-align: center !important;
  }
  .pic,
  .pic::after {
    width: 20vw;
    margin: 0;
    height: 20vw;
  }

  /* Accordion.js */
  .cards-grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 4vw;
    margin: 0 1rem;
  }
  .cards-grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 4vw;
    margin: 0 1rem;
  }

  /* Projects.js */
  .cards-grid-projec {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    position: relative;
    z-index: 1;
    margin: 3rem 1rem 3rem 0;
  }
  .cards-grid-projec::before {
    position: absolute;
    content: "";
    border-radius: var(--radius);
    width: 90%;
    height: 50%;
    left: 0;
    top: -1rem;
    z-index: -1;
  }
  .cards-grid-projec:nth-child(2n + 1)::before {
    background: var(--color-accent);
    opacity: 0.1;
  }
  .cards-grid-projec:nth-child(2n)::before {
    background: var(--color-primary);
    opacity: 0.05;
  }
  .cards-grid-projec h3 {
    margin: 0.5rem 0;
  }
  .card-projects {
    margin: 0 0 0 2rem;
    position: relative;
    width: 150%;
    box-shadow: var(--dark-shadow);
    border: none !important;
  }
  .card-projects::before {
    display: none;
    border: none;
  }
  .card-img {
    position: relative;
    display: inline-block;
  }
  .card-img::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: var(--radius);
    top: -2rem;
    left: 1rem;
    z-index: -1;
  }
  .card-img::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    border-radius: var(--radius);
  }
  img {
    border-radius: var(--radius);
  }
  .cards-grid-projec:nth-child(2n) .card-img::after {
    background: var(--color-primary);
    opacity: 0.4;
  }
  .cards-grid-projec:nth-child(2n + 1) .card-img::after {
    background: var(--color-accent);
    opacity: 0.5;
  }
  .cards-grid-projec:nth-child(2n) .card-img::before {
    border: var(--width) solid var(--color-primary);
  }
  .cards-grid-projec:nth-child(2n + 1) .card-img::before {
    border: var(--width) solid var(--color-accent);
  }
  .contacts {
    width: 50vw;
    height: 80vh;
    text-align: center;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contacts h1 {
    font-size: xx-large;
    margin: 1rem 0;
  }
  .contacts p {
    margin: 1rem 0;
  }
  footer {
    margin: 2rem 0 1rem 0;
    font-size: smaller;
  }
}

/* EXTRA LARGE SCREENS - Large L */
@media only screen and (min-width: 1025px) {
  :root {
    font-size: 20px;
  }
  .container {
    padding: 0 15vw;
  }
  .pic {
    width: 15vw;
    height: 100%;
  }
  .pic::before,
  .pic::after {
    height: 15vw;
    width: 15vw;
  }
  .theme-popup {
    top: -8rem;
    padding-top: 6rem;
  }
}
/* NOT MOBILE */
@media only screen and (min-width: 426px) {
  .card-experience-grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .card-experience-grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 2vw;
  }
}

/* ANIMATION */
@keyframes underline {
  0% {
    transform: scaleX(0);
    transform-origin: 0% 0%;
    border-bottom: 0.6rem solid var(--color-primary);
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    border-bottom: 0.6rem solid var(--color-accent);
  }
}

@keyframes fade_in_aside {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes ping {
  0% {
    transform: scale(0.6);
    opacity: 0.3;
  }
  80% {
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    transform: scale(2.2);
    opacity: 0;
  }
}

@keyframes header-primary {
  0% {
    transform: translate(-5vh, -10vh);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes header-accent {
  0% {
    transform: translate(5vh, 5vh);
  }
  100% {
    transform: translate(-10vh, -5vh);
  }
}
